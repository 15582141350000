<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog
        modal-dialog-centered
        modal-xl
        modal-fullscreen-lg-down
        modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            {{ $t("ingredients.ingredients") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div
            class="row custom-input mx-md-5 px-md-5 whitebg-input"
            v-for="p in addProducts"
            :key="p.id"
          >
            <div class="col-3">
              <label class="form-label bold-12 text-gray">{{
                $t("ingredients.quantity")
              }}</label>
              <div class="fix-end-input">
                <input
                  type="number"
                  v-model="p.quantity"
                  class="form-control"
                  min="1"
                />
                <span>
                  {{
                    units[
                      p.products.find((e) => e.variant_id === p.id)?.unit ?? 0
                    ] ?? ""
                  }}
                </span>
              </div>
            </div>
            <div class="col">
              <div class="mb-3">
                <label class="form-label bold-12 text-gray">{{
                  $t("chart1.product")
                }}</label>
                <v-select
                  v-model:options="p.products"
                  v-on:search="(s, k) => fetchProducts(s, k, p)"
                  v-model="p.id"
                  :filterable="false"
                  :placeholder="$t(`copyProducts.selectProduct`)"
                  :reduce="(c) => c.variant_id"
                  :getOptionKey="(c) => c.variant_id"
                  v-on:option:selected="(e) => changeProducts(e.variant_id)"
                  v-on:option:deselected="() => changeProducts(0)"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching && search.length > 3">
                      {{ $t("copyProducts.noProductsFoundFor") }}
                      <em>{{ search }}</em
                      >.
                    </template>
                    <span v-else>{{
                      $t("copyProducts.startTypingToSearchForAProduct")
                    }}</span>
                  </template>
                  <template v-slot:selected-option="option">
                    <div class="row">
                      <div class="col-auto px-0">
                        <img
                          :src="mediaServer + '/' + option?.image"
                          v-if="
                            option?.image !== null &&
                            option?.image !== undefined
                          "
                          class="product-image"
                          alt=""
                        />
                        <BaseIcon
                          v-else
                          name="no-image"
                          class="product-image"
                        />
                      </div>
                      <div class="col">
                        <div>
                          <b>{{ option.name }}</b>
                        </div>
                        <div>
                          <span
                            v-for="char in option.caracteristics"
                            :key="char"
                            class="me-2"
                          >
                            {{ char }}
                          </span>
                          <span class="me-2">
                            {{ option.serial }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:option="option">
                    <span class="me-2">
                      {{ option.serial }}
                    </span>
                    <span
                      v-for="char in option.caracteristics"
                      :key="char"
                      class="me-2"
                    >
                      {{ char }}
                    </span>
                    <b>{{ option.name }}</b>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 mt-4">
              <label class="cstm-check d-flex">
                <span class="regular-12 text-black mt-3">
                  {{ $t("ingredients.lock") }}
                </span>
                <input type="checkbox" v-model="lockData" :value="true" />
                <span class="checkmark mt-3"></span>
              </label>
            </div>
            <div class="col-6 text-end mt-4">
              <button
                class="btn btn-type-2"
                v-on:click="save()"
                :disabled="lockData && lock"
              >
                <span>{{ $t("finance.save") }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from "@/components/icons/BaseIcon";
const bootstrap = require("bootstrap");
import http from "@/modules/http";
import { useStore } from "vuex";

export default {
  name: "IngredientsModal",
  props: {
    materials: Array,
    type: Number,
    id: Number,
    category: Number,
    lock: Boolean,
  },
  data() {
    return {
      store: useStore(),
      myModal: undefined,
      lockData: this.lock,
      addProducts: [{ id: null, quantity: 1, products: [] }],
      original: [],
      units: {
        0: "",
        1: "Gram",
        2: "Meter",
        3: "Square meter",
        4: "Piece",
        5: "Bundle",
      },
    };
  },
  watch: {
    lock() {
      this.lockData = this.lock;
    },
  },
  computed: {
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
  },
  methods: {
    showModal() {
      this.addProducts = [];
      if (this.materials !== undefined && this.materials.length > 0) {
        for (const material of this.materials) {
          this.original.push({
            id: material.variant_id,
            amount: material.product_components.amount,
          });
          this.addProducts.push({
            id: material.variant_id,
            quantity: material.product_components.amount,
            products: [material],
          });
        }
      }
      this.addProducts.push({ id: null, quantity: 1, products: [] });

      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
    },
    changeProducts(variant_id) {
      let hasNull = false;
      let quantity = 0;
      let count = 0;
      for (const p in this.addProducts) {
        if (this.addProducts[p].id === null) {
          if (p !== 0) {
            this.addProducts.splice(p, 1);
          } else {
            hasNull = true;
          }
        } else if (this.addProducts[p].id === variant_id) {
          count++;
          quantity += this.addProducts[p].quantity;
          if (count > 1) {
            this.addProducts.splice(p, 1);
          }
        }
      }
      if (count > 1) {
        for (const p in this.addProducts) {
          if (this.addProducts[p].id === variant_id) {
            this.addProducts[p].quantity = quantity;
            break;
          }
        }
      }
      if (!hasNull) {
        this.addProducts.unshift({ id: null, quantity: 1, products: [] });
      }
    },
    save() {
      let productIDs = [];
      let sendData = {
        materials: [],
        delete: [],
        //is_product: this.type === 1,
        category_id: this.category,
        lock: this.lockData,
      };
      let materials = [];
      for (const product of this.addProducts) {
        if (product.id) {
          productIDs.push(product.id);
          console.log(product);
          let original = this.original.find((e) => e.id === product.id);
          if (original === undefined || product.quantity != original.amount) {
            sendData.materials.push({
              id: product.id,
              amount:
                typeof product.quantity === "number"
                  ? product.quantity
                  : parseFloat(product.quantity?.replace(/,/g, ".")),
            });
          }

          let material = product.products.find(
            (e) => e.variant_id === product.id
          );
          material.product_components = {
            amount:
              typeof product.quantity === "number"
                ? product.quantity
                : parseFloat(product.quantity?.replace(/,/g, ".")),
          };
          materials.push(material);
        }
      }

      for (const product of this.original) {
        if (productIDs.indexOf(product.id) < 0) {
          sendData.delete.push(product.id);
        }
      }

      this.$emit("change", materials);
      this.$emit("changeRaw", sendData);
      this.$emit("update:lock", this.lockData);
      this.myModal.hide();

      /*
      http
        .fetch(`/products/${this.id}/components`, sendData, true)
        .then((data) => {
          console.log(data);
        });
       */
    },
    fetchProducts(search, loading, p) {
      if (search.length < 3) {
        return;
      }
      loading(true);
      http
        .fetch("/products/materials/search", {
          q: search,
          category_id: this.category,
        })
        .then((data) => {
          p.products = data;
          loading(false);
        })
        .catch(() => {
          loading(false);
        });
    },
  },
  emits: ["changeRaw", "change", "update:lock"],
  components: {
    BaseIcon,
  },
};
</script>
